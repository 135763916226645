import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class GoogleTagManagerService {
  constructor(@Inject(DOCUMENT) private document: Document) {}

  initializeGTM(id: string = 'GTM-NQHG8SNH') {
    try {
      // Initialize dataLayer
      (window as any).dataLayer = (window as any).dataLayer || [];
      (window as any).dataLayer.push({
        'gtm.start': new Date().getTime(),
        event: 'gtm.js',
      });

      // Create and inject GTM script
      const script = this.document.createElement('script');
      script.async = true;
      script.src = `https://www.googletagmanager.com/gtm.js?id=${id}`;

      const firstScript = this.document.getElementsByTagName('script')[0];
      firstScript.parentNode?.insertBefore(script, firstScript);

      // Add noscript iframe
      const noscript = this.document.createElement('noscript');
      const iframe = this.document.createElement('iframe');
      iframe.src = `https://www.googletagmanager.com/ns.html?id=${id}`;
      iframe.height = '0';
      iframe.width = '0';
      iframe.style.display = 'none';
      iframe.style.visibility = 'hidden';

      noscript.appendChild(iframe);
      this.document.body.appendChild(noscript);
    } catch (e) {
      console.error('Error initializing GTM:', e);
    }
  }

  pushToDataLayer(data: any) {
    if ((window as any).dataLayer) {
      (window as any).dataLayer.push(data);
    }
  }
}
