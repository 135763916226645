<h1 mat-dialog-title class="h1-dark fs-20-i fw-500-i">
  Add products to stream
</h1>
<div mat-dialog-content>
  <div class="pb-10 pt-10">
    <div class="pb-10 pt-10">
      <div class="form-item">
        <label class="fs-12 fw-400 lh-16 text-0006 d-block ff-roboto"
          >Select and add from the product list that you want to showcase in the
          stream</label
        >
        <mat-form-field class="table-search-input mnw-35" appearance="outline">
          <input type="search" matInput placeholder="Search" />
          <mat-icon matSuffix>search</mat-icon>
        </mat-form-field>
      </div>
      <div class="responsive-table">
        <table id="showcaseallItems" class="w-100">
          <thead>
            <tr>
              <th class="w-60px">
                <mat-checkbox
                  (change)="$event ? masterToggle() : null"
                  [checked]="selection.length > 0 && isAllSelected()"
                  [indeterminate]="selection.length > 0 && !isAllSelected()"
                ></mat-checkbox>
              </th>
              <th>&nbsp;</th>
              <th>Product</th>
              <th>Category</th>
              <th>Price</th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="let row of productList"
              (click)="toggleSelection(row, $event)"
            >
              <td class="w-60px selection-checkbox">
                <mat-checkbox
                  (click)="$event.stopPropagation()"
                  (change)="$event ? toggleSelection(row, $event) : null"
                  [checked]="isSelected(row)"
                >
                </mat-checkbox>
              </td>
              <td>
                <img
                  [src]="row.imagePath"
                  class="responsive-image mxw-46px b-s1 brds-2px bc-00004"
                />
              </td>
              <td>{{ row.name }}</td>
              <td>{{ row.categoryName }}</td>
              <td>${{ row?.price / 100 }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div
      mat-dialog-actions
      class="pstn-relative m-0-24-neg p-0-24-i bt-s1-i bc-00012-i mt-15"
    >
      <mat-spinner
        diameter="25"
        class="color-primary pstn-absolute t24 l4"
        [class.hidden]="!inProgress"
      >
      </mat-spinner>
      <button
        mat-button
        class="tt-upper text-primary ml-auto-i"
        (click)="onCancelClick()"
      >
        <span>Cancel</span>
      </button>
      <button
        mat-raised-button
        color="primary"
        class="tt-upper ml-0-i"
        (click)="addSelectionToStream()"
      >
        <span>Add to Stream</span>
      </button>
    </div>
  </div>
</div>
