import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import _ from 'lodash';

import { IItem } from 'src/app/models/item.model';
import { ShopService } from 'src/app/services/shop.service';
import { ProductDetailsDialogComponent } from '../product-details-dialog.component';
import { ProductTypeEnum } from 'src/app/enums/product-type-enum';

@Component({
  selector: 'showcased-products',
  styleUrls: [
    '../../../../assets/css/common/stream/stream-showcase-products.components.css',
  ],
  templateUrl:
    '../../../views/common/session/stream-showcased-products.component.html',
})
export class StreamShowcasedProductsComponent implements OnInit, OnDestroy {
  @Input() streamId: string;
  @Input() retailerDomain: string;

  showcaseProductItems: IItem[] = [];

  constructor(public dialog: MatDialog, private shopService: ShopService) {}

  ngOnInit() {
    this.shopService
      .showcaseProductsByScheuleId(this.streamId)
      .toPromise()
      .then((items: IItem[]) => {
        this.showcaseProductItems = items.filter(function (item) {
          return item.price != null;
        });
      });
  }

  openProductDetailDialog(productItem) {
    if (_.get(productItem, 'type') === ProductTypeEnum.EXTERNAL_PRODUCT_TYPE) {
      window.open(_.get(productItem, 'externalProductLink'), '_blank');
      return;
    }

    this.dialog.open(ProductDetailsDialogComponent, {
      data: {
        productId: productItem._id,
        productItem: productItem,
        retailerDomain: this.retailerDomain,
        retailerStoreName: undefined,
      },
      minWidth: '62%',
    });
  }

  ngOnDestroy(): void {}
}
