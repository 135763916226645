import { EventEmitter } from '@angular/core';
import { RTCSubscriber } from 'red5pro-webrtc-sdk';
import { AppConfig } from 'src/app/app.config';

export abstract class Red5ProSubscriber {
  public static subscriber: any;
  public static connectionClosed: EventEmitter<boolean> = new EventEmitter();

  public static connect = async (streamName: string) => {
    try {
      Red5ProSubscriber.subscriber = new RTCSubscriber();
      Red5ProSubscriber.subscriber.on(
        '*',
        Red5ProSubscriber.handleSubscriberEvent
      );
      await Red5ProSubscriber.subscriber.init({
        protocol: AppConfig.settings.red5pro.protocol,
        port: AppConfig.settings.red5pro.port,
        host: AppConfig.settings.red5pro.host,
        app: AppConfig.settings.red5pro.applicationName,
        streamName: streamName,
        maintainConnectionOnSubscribeErrors: true,
        rtcConfiguration: {
          iceServers: [{ urls: 'stun:stun2.l.google.com:19302' }],
          iceCandidatePoolSize: 2,
          bundlePolicy: 'max-bundle',
        },
        signalingSocketOnly: false,
        mediaElementId: 'red5pro-subscriber',
        subscriptionId:
          streamName + Math.floor(Math.random() * 0x10000).toString(16),
      });

      await Red5ProSubscriber.subscriber.subscribe();
    } catch (e) {
      console.error('Subscription error:', e);
      throw e;
    }
  };

  private static handleSubscriberEvent = (event: any) => {
    console.log('Event:', event.type, event);
    switch (event.type) {
      case 'Subscribe.Connection.Closed':
        Red5ProSubscriber.connectionClosed.emit(true);
        break;
      case 'Subscribe.Stop':
        Red5ProSubscriber.connectionClosed.emit(true);
        break;
      // Handle more events like SDP errors or negotiation failure:
      case 'Subscribe.Fail':
        console.error('Subscribe failed:', event);
        break;
      case 'RTCSubscriber.MediaNegotiationFailed':
        console.error('SDP Negotiation failed:', event);
        break;
    }
  };
}
