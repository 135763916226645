import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import _ from 'lodash';

import { AddItemDialogComponent } from './add-item.dialog.component';
import { AddTaxDialogComponent } from './add-tax.dialog.component';
import { AuthenticationService, ItemService } from 'src/app/services';
import { DeleteItemDialogComponent } from './delete-item.dialog.component';
import { AddImageDialogComponent } from './add-image.dialog.component';

@Component({
  selector: 'app-item',
  templateUrl: '../../views/item/item-list.component.html',
})
export class ItemListComponent implements OnInit {
  productList = [];
  categories = [];
  isLoading = false;
  subscriptions = new Subscription();
  constructor(
    public dialog: MatDialog,
    public itemService: ItemService,
    private authenticationService: AuthenticationService
  ) {}

  ngOnInit(): void {
    this.loadProducts();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  async loadProducts() {
    const userId = this.authenticationService.currentUserId;
    this.isLoading = true;
    this.itemService.getItemsList(userId).subscribe((items: any) => {
      this.productList = items;
      this.isLoading = false;
    });
  }

  openAddProductDialog(product: {}) {
    const dialogRef = this.dialog.open(AddItemDialogComponent, {
      data: product,
    });
    dialogRef
      .afterClosed()
      .toPromise()
      .then((res) => {
        location.reload();
      });
  }

  openAddTaxDialog() {
    const dialogRef = this.dialog.open(AddTaxDialogComponent);
    dialogRef
      .afterClosed()
      .toPromise()
      .then((res) => {
        location.reload();
      });
  }

  openDeleteProductDialog(product) {
    const dialogRef = this.dialog.open(DeleteItemDialogComponent, {
      data: product,
    });
    dialogRef
      .afterClosed()
      .toPromise()
      .then((res) => {
        location.reload();
      });
  }

  openAddImageDialog() {
    const dialogRef = this.dialog.open(AddImageDialogComponent, {});
    dialogRef
      .afterClosed()
      .toPromise()
      .then((res) => {
        location.reload();
      });
  }
}
