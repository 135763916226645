<app-google-tag-manager id="GTM-NQHG8SNH"></app-google-tag-manager>
<!-- header -->
<app-header
  class="w-100 bg-white d-block mb-2"
  *ngIf="!routeService.isSignupSignPage"
></app-header>
<!-- body -->
<router-outlet></router-outlet>
<!-- footer -->
<app-footer *ngIf="!routeService.isSignupSignPage"></app-footer>
