<div class="mw-580px-i mnw-320px">
  <h1 mat-dialog-title class="h1-dark fs-20-i fw-500-i">
    {{ isCreating ? "Create" : "Update" }} Project
  </h1>
  <form
    name="projectForm"
    [formGroup]="projectForm"
    (ngSubmit)="onSubmit()"
    method="post"
    enctype="multipart/form-data"
    *ngIf="projectForm"
  >
    <div class="pb-10 pt-10">
      <div class="pb-10 pt-10">
        <div class="form-item">
          <label class="p3-dark-fwm mb-15 d-block text-black"
            >Project Type</label
          >
          <mat-radio-group
            aria-label="Select an option"
            color="primary"
            [formControl]="projectType"
          >
            <mat-radio-button
              checked="false"
              value="{{ showcaseProjectType }}"
              class="mr-10"
              >Showcase Project</mat-radio-button
            >
            <!-- <mat-radio-button checked="true" value="{{ retailerProjectType }}"
              >Retailer Project</mat-radio-button
            > -->
          </mat-radio-group>
          <div
            class="mat-form-field-subscript-wrapper pstn-relative-i"
            [class.hidden]="!projectType.invalid"
          >
            <p class="mat-error m-0-i">{{ getErrorMessage("projectType") }}</p>
          </div>
        </div>
        <div class="form-item mt-15">
          <div
            class="row d-block fade-out mb-15"
            [class.fade-in]="projectType.value == retailerProjectType"
          >
            <div class="col-12">
              <label class="p3-dark-fwm mb-15 d-block text-black"
                >Select retailer product</label
              >
              <div style="max-height: 100px; overflow: auto">
                <mat-radio-group
                  aria-label="Select a product"
                  color="primary"
                  [formControl]="selectedRetailerProductId"
                >
                  <mat-radio-button
                    *ngFor="let product of retailerProducts"
                    value="{{ product._id }}"
                    class="mr-10"
                    >{{ product.name }}</mat-radio-button
                  >
                  <br />
                </mat-radio-group>
              </div>
              <mat-error *ngIf="selectedRetailerProductId.invalid"
                >{{ getErrorMessage("selectedRetailerProductId") }}
              </mat-error>
            </div>
          </div>
          <div class="row d-block">
            <div class="col-12">
              <mat-form-field appearance="outline" class="d-block-i">
                <mat-label>Project Name</mat-label>
                <input
                  matInput
                  placeholder="Project Name"
                  [formControl]="name"
                  required
                />
                <mat-error *ngIf="name.invalid"
                  >{{ getErrorMessage("name") }}
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row d-block">
            <div class="col-12">
              <mat-form-field appearance="outline" class="d-block-i">
                <mat-label>Project Description</mat-label>
                <input
                  matInput
                  placeholder="Description"
                  [formControl]="description"
                  required
                />
                <mat-error *ngIf="description.invalid"
                  >{{ getErrorMessage("description") }}
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div
            class="row d-block fade-out"
            [class.fade-in]="projectType.value == showcaseProjectType"
          >
            <div class="col-12">
              <mat-form-field appearance="outline" class="d-block-i">
                <mat-label>Project Price</mat-label>
                <input
                  matInput
                  placeholder="Price"
                  [formControl]="price"
                  required
                  type="number"
                />
                <mat-error *ngIf="price.invalid"
                  >{{ getErrorMessage("price") }}
                </mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="row d-flex mt-25">
            <div class="col-12">
              <div class="form-item">
                <label class="p3-dark-fwm mb-15 d-block text-black"
                  >Thumbnail</label
                >
                <div class="p-15 bg-blk-004 pstn-relative">
                  <input
                    id="thumbnail"
                    class="mat-stroke-button-file-input"
                    type="file"
                    (change)="onThumbnailChange($event)"
                    required
                    accept="image/*"
                  />
                  <div
                    class="mat-form-field-subscript-wrapper pstn-relative-i"
                    [class.hidden]="!thumbnailIsInvalid"
                  >
                    <p class="mat-error m-0-i">Thumbnail is required</p>
                  </div>
                </div>
                <mat-hint class="d-block w-100 mt-10 pl-10-i"
                  >Please upload a thumbnail for the stream with 4:3 aspect
                  ratio</mat-hint
                >
              </div>
            </div>
          </div>
          <div class="row d-flex mt-25">
            <div class="col-12">
              <div class="form-item">
                <label class="p3-dark-fwm mb-15 d-block text-black"
                  >Video</label
                >
                <div class="p-15 bg-blk-004 pstn-relative">
                  <input
                    id="video"
                    class="mat-stroke-button-file-input"
                    type="file"
                    (change)="onVideoChange($event)"
                    accept="video/mp4,video/x-m4v,video/*"
                    required
                  />
                  <div
                    class="mat-form-field-subscript-wrapper pstn-relative-i"
                    [class.hidden]="!videoIsInvalid"
                  >
                    <p class="mat-error m-0-i">Video is required</p>
                  </div>
                </div>
                <mat-hint class="d-block w-100 mt-10 pl-10-i"
                  >Please upload a video for the stream with 4:3 aspect
                  ratio</mat-hint
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="mat-dialog-actions pstn-relative m-0-24-neg p-0-24-i bt-s1-i bc-00012-i mt-15"
      >
        <mat-spinner
          diameter="25"
          class="color-primary pstn-absolute t24 l4"
          [class.hidden]="!inProgress"
        >
        </mat-spinner>
        <button
          mat-button
          class="tt-upper text-primary ml-auto-i"
          (click)="onCancelClick()"
        >
          <span>Cancel</span>
        </button>
        <button
          mat-raised-button
          color="primary"
          type="submit"
          class="tt-upper ml-0-i"
          disabled="{{ inProgress }}"
        >
          <span>{{ isCreating ? "Create" : "Update" }} Project</span>
        </button>
      </div>
    </div>
  </form>
</div>
