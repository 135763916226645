import { Component, Input, OnInit } from '@angular/core';
import { GoogleTagManagerService } from './google-tag-manager.service';

@Component({
  selector: 'app-google-tag-manager',
  template: '',
})
export class GoogleTagManagerComponent implements OnInit {
  @Input() id: string = 'GTM-NQHG8SNH';

  constructor(private gtmService: GoogleTagManagerService) {}

  ngOnInit() {
    this.gtmService.initializeGTM(this.id);
  }
}
