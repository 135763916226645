import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GoogleTagManagerComponent } from './google-tag-manager.component';
import { GoogleTagManagerService } from './google-tag-manager.service';

@NgModule({
  declarations: [GoogleTagManagerComponent],
  imports: [CommonModule],
  exports: [GoogleTagManagerComponent],
  providers: [GoogleTagManagerService],
})
export class GoogleTagManagerModule {}
