<div
  class="d-block w-100 pl-100 pr-100 ff-roboto pl-sm-0 pr-sm-0 hidden-sm d-flex-row"
>
  <a mat-button [matMenuTriggerFor]="categoryMenu">Shop by Category</a>
  <mat-menu #categoryMenu="matMenu" class="categoriesMenu">
    <button mat-menu-item *ngFor="let citem of categories">
      <a href="/shops/all/{{ citem.id }}">{{ citem.category }}</a>
    </button>
  </mat-menu>

  <a mat-button href="/#live-demonstration">Live Shows</a>
  <a mat-button href="/#recently-aired">Recently Aired</a>
  <a mat-button href="/shops/new-in">New Arrivals</a>
  <a mat-button href="/shops/all">All Shops</a>
  <a mat-button href="/shops/all?isCreatorsOnly=true">Creators</a>
  <a mat-button routerLink="/register" [state]="{ key: 'sell' }"
    >Sell On Marketplace</a
  >
</div>
<div
  class="w-100 ff-roboto hidden d-block-sm ta-center"
  fxLayout="row"
  fxLayoutAlign="space-between"
>
  <a mat-button href="/#live-demonstration">Live Shows</a>
  <a mat-button href="/#recently-aired">Recently Aired</a>
  <a mat-button href="/shops/all">All Shops</a>
</div>
