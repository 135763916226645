import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { RoleEnum } from 'src/app/enums/role.enum';
import { GoogleTagManagerService } from 'src/app/google-tag-manager/google-tag-manager.service';
import { AuthenticationService } from 'src/app/services';
import { RememberMeService } from 'src/app/services/rememberMe.service';

@Component({
  selector: 'app-login',
  templateUrl: '../../views/login/login.component.html',
  styleUrls: ['../../../assets/css/login/login.component.css'],
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  inProgress: Boolean = false;
  returnUrl: string;
  rememberMeChecked: boolean = false;

  email = new FormControl('', [Validators.required, Validators.email]);
  password = new FormControl('', [
    Validators.required,
    Validators.minLength(8),
  ]);
  rememberMe = new FormControl('');

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthenticationService,
    private remembermeService: RememberMeService,
    private gtmService: GoogleTagManagerService,
    private route: ActivatedRoute,
    private router: Router,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';

    if (this.remembermeService.userName) {
      this.rememberMeChecked = true;
      this.email.setValue(this.remembermeService.userName);
    }
    this.loginForm = this.formBuilder.group({
      email: this.email,
      password: this.password,
    });
  }

  getErrorMessage(fieldKey: string) {
    switch (fieldKey) {
      case 'email':
        if (this.email.hasError('required')) {
          return 'Email is required';
        }
        return this.email.hasError('email') ? 'Not a valid email' : '';
        break;
      case 'password':
        if (this.password.hasError('required')) {
          return 'Password is required';
        }
        if (this.password.errors.minlength) {
          return 'Password can not be shorter than 8 characters';
        }
        break;
    }
  }

  onSubmit() {
    this.gtmService.pushToDataLayer({
      event: 'login_attempt',
      timestamp: new Date().toISOString(),
      email: this.email.value,
    });

    this.inProgress = true;
    if (this.loginForm.invalid) {
      this.inProgress = false;
      return;
    }

    this.authService
      .login(this.email.value, this.password.value)
      .toPromise<any>()
      .then((user) => {
        // handle success response
        this.inProgress = false;
        if (this.rememberMe.value) {
          this.remembermeService.userName = this.email.value;
        }

        if (this.returnUrl) {
          this.router.navigateByUrl(this.returnUrl);
          return;
        }

        if (this.authService.currentUserRole === RoleEnum.retailer) {
          const userDomain: string = user.domain || '';
          this.router.navigate([`/store/${userDomain}/sessions`]);
          return;
        }

        this.router.navigate(['/']);
      })
      .catch((err: HttpErrorResponse) => {
        // handle error response
        const errorMessage = err.error.error || err.error;
        this.inProgress = false;
        this.snackBar.open(errorMessage || errorMessage || 'Error!', 'Close', {
          duration: 10000,
          panelClass: ['mat-error-bg'],
        });
      });
  }
}
